.cart {
  padding: 5px 7px;
  display: flex;
  flex-direction: column;

  @include themify($themes) {
    background-color: themed('gallery');
  }

  @media (min-width: $breakpoint-md) {
    padding: 18px 0;
    flex-grow: unset;
  }

  @media (min-width: $breakpoint-lg) {
    margin-right: 10px;
    padding: 29px 34px 19px 34px;
    flex-grow: 1;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 36px 8.6%;
  }
}

.cart-container {
  height: calc(100dvh - 47px);
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-md) {
    height: calc(100dvh - 69px);
    padding: 0 33px;

    @include themify($themes) {
      background-color: themed('gallery');
    }
  }

  @media (min-width: $breakpoint-md) {
    margin: 0 10px;
    margin-bottom: 10px;
  }

  @media (min-width: $breakpoint-lg) {
    padding: 0;
    height: calc(100dvh - 50px);
    flex-direction: row;
    height: auto;
    display: flex;
    flex-wrap: wrap;

    @include themify($themes) {
      background-color: transparent;
    }
  }

  @media (min-width: $breakpoint-xl) {
    height: calc(100dvh - 70px);
  }
}

.cart-title {
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  padding: 12px 10px 6px 10px;
  margin-bottom: 5px;

  @include themify($themes) {
    color: themed('text');
    background-color: themed('back');
  }

  @media (min-width: $breakpoint-md) {
    font-size: 12px;
    padding: 0;
    margin-bottom: 12px;
    text-align: center;

    @include themify($themes) {
      background-color: transparent;
    }
  }

  @media (min-width: $breakpoint-lg) {
    margin-bottom: 15px;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 14px;
    margin-bottom: 31px;
  }
}

.cart-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  display: block;
  overflow-y: auto;
  max-height: calc(100dvh - 246px);

  .check-body & {
    max-height: unset !important;
  }

  td:last-child {
    width: 1%;
    white-space: nowrap;
  }

  @media (min-width: $breakpoint-md) {
    max-height: calc(100dvh - 330px);
    display: block;
  }

  @media (min-width: $breakpoint-lg) {
    max-height: calc(100dvh - 178px);
  }

  @media (min-width: $breakpoint-xl) {
    max-height: calc(100dvh - 249px);
  }
}

.cart-head {
  display: none;

  @media (min-width: $breakpoint-md) {
    display: table-header-group;
  }
}

.cart-th {
  font-size: 10px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: left;
  border: none;
  border-bottom: 1px solid;
  padding: 9px 0;
  width: 18%;

  &:first-child {
    width: 48%;
  }

  &:last-child {
    width: 16%;
  }

  @include themify($themes) {
    color: themed('tableTh');
    border-color: themed('cartBorder');
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 12px;
    padding: 10px 0;
  }
}

.cart-tr {
  position: relative;
  display: block;
  padding: 12px 0;
  border-bottom: 1px solid;

  @include themify($themes) {
    border-color: themed('cartBorder');
  }

  @media (min-width: $breakpoint-md) {
    display: table-row;
    padding: 0;
    border-bottom: unset;
  }
}

.cart-td {
  display: block;
  border: none;

  .check-body & {
    @media (min-width: $breakpoint-md) {
      border-bottom: 1px solid;
    }
  }

  @include themify($themes) {
    border-color: themed('cartBorder');
  }

  @media (min-width: $breakpoint-md) {
    display: table-cell;
    padding: 22px 0;
    border-bottom: 1px solid;
  }

  @media (min-width: $breakpoint-lg) {
    border-bottom: 1px solid;
  }

  @media (min-width: $breakpoint-xl) {
    border-bottom: none;

    tr:not(:last-child) & {
      @include themify($themes) {
        border-color: themed('cartBorder');
      }

      border-bottom: 1px solid;
    }
  }

  &.cart-sum,
  &.cart-total {
    display: inline-block;
    margin-top: 10px;
    margin-right: 10px;

    @media (min-width: $breakpoint-md) {
      display: table-cell;
      margin: 0;
    }
  }
}

.cart-file-name {
  font-size: 10px;
  font-weight: normal;
  display: block;
  text-align: center;
  margin-top: 2px;

  @include themify($themes) {
    color: themed('text');
  }

  @media (min-width: $breakpoint-lg) {
    font-size: 12px;
    margin-top: 5px;
  }
}

.cart-file {
  width: min-content;
  vertical-align: baseline;
  width: 100%;

  @media (min-width: $breakpoint-md) {
    padding-left: 20px;
  }

  @media (min-width: $breakpoint-lg) {
    padding-left: 25px;
  }
}

.cart-file-title {
  font-size: 10px;
  display: block;
  font-weight: normal;
  line-height: 1.5;

  @include themify($themes) {
    color: themed('text');
  }

  &.isBold {
    font-size: 12px;
    font-weight: bold;
  }

  @media (min-width: $breakpoint-lg) {
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 1;
  }
}

.cart-file-text {
  font-size: 10px;
  font-weight: normal;
  display: block;

  @include themify($themes) {
    color: themed('text');
  }

  @media (min-width: $breakpoint-md) {
    line-height: 1.5;
  }

  @media (min-width: $breakpoint-lg) {
    font-size: 12px;
  }
}

.cart-total {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.5;

  @include themify($themes) {
    color: themed('text');
  }

  @media (min-width: $breakpoint-lg) {
    font-size: 12px;
    font-weight: 500;
  }

  @media (min-width: $breakpoint-xl) {
    font-weight: 900;
  }
}

.cart-quantity,
.cart-quantity-btn {
  height: 24px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  border: none;
  text-align: center;
  width: 23px;

  &:focus {
    outline: none;
  }

  @include themify($themes) {
    color: themed('text');
    background-color: themed('btnGallery');
  }
}

.cart-quantity-btn {
  cursor: pointer;
}

.cart-btn {
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;

  &:focus {
    outline: none;
  }

  @include themify($themes) {
    background-color: themed('btnGallery');
  }
  &:first-child {
    bottom: 18px;
    right: 0;
  }

  &:last-child {
    top: 11px;
    right: 0;
  }

  @media (min-width: $breakpoint-md) {
    position: static;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.cart-icon {
  width: 14px;
  height: 14px;
}

.cart-image-container {
  margin-right: 15px;

  @media (min-width: $breakpoint-md) {
    margin-right: 0;
  }
}

.cart-image {
  @media (min-width: $breakpoint-md) {
    display: grid;
    grid-template-columns: 0.9fr 1fr;
  }

  @media (min-width: $breakpoint-lg) {
    padding-right: 10px;
    grid-template-columns: 0.7fr 1fr;
  }

  @media (min-width: $breakpoint-xl) {
    grid-template-columns: 0.6fr 1fr;
  }
}

.cart-img-container {
  height: auto;
}

.cart-img {
  width: auto;
  height: auto;
  object-fit: contain;
  max-width: 100%;
  max-height: 14vh;
  object-position: center;
  margin: 0 auto;
  display: flex;
}

.cart-price {
  vertical-align: top;
}

.cart-sidebar {
  padding: 0 7px;
  margin: 0;
  flex: unset;

  @include themify($themes) {
    background-color: themed('gallery');
  }

  @media (min-width: $breakpoint-md) {
    padding-left: 55%;
  }

  @media (min-width: $breakpoint-lg) {
    padding: 29px 0;
    flex: auto;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 36px 0;
  }
}

.cart-sidebar-title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  padding: 14px 10px 7px 10px;

  @include themify($themes) {
    color: themed('text');
    background-color: themed('back');

    @media (min-width: $breakpoint-lg) {
      background-color: unset;
    }
  }

  @media (min-width: $breakpoint-md) {
    padding: 21px 21px 14px 21px;
  }

  @media (min-width: $breakpoint-lg) {
    font-size: 12px;
    padding: 0 14px;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 0 21px;
    font-size: 14px;
  }
}

.cart-sidebar-list {
  @media (min-width: $breakpoint-lg) {
    padding: 10px 14px 15px 14px;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 19px 21px;
  }
}

.cart-sidebar-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.3;
  padding: 0 10px;

  .confirm-footer & {
    padding: 0;
    line-height: 1.5;

    @include themify($themes) {
      background-color: transparent;
    }
  }

  @include themify($themes) {
    background-color: themed('back');

    @media (min-width: $breakpoint-lg) {
      background-color: unset;
    }
  }

  @media (min-width: $breakpoint-md) {
    padding: 0 21px;
  }

  @media (min-width: $breakpoint-lg) {
    line-height: 1.5;
    padding: 0;
  }
}

.cart-sidebar-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 14px 10px;

  @include themify($themes) {
    border-color: themed('sidebarBorder');
    background-color: themed('back');

    @media (min-width: $breakpoint-lg) {
      background-color: unset;
    }
  }

  @media (min-width: $breakpoint-md) {
    padding: 0 21px 21px 21px;
  }

  @media (min-width: $breakpoint-lg) {
    // padding: 15px 14px;
    padding: 15px 0;
    border-top: 1px solid;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 17px 0;
  }
}

.cart-sidebar-item-text {
  font-weight: normal;
  font-size: 10px;

  @include themify($themes) {
    color: themed('text');
  }

  &.isBold {
    font-weight: 900;
  }

  @media (min-width: $breakpoint-md) {
    font-size: 12px;
  }
}

.cart-sidebar-total {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.3;

  @include themify($themes) {
    color: themed('text');
  }

  @media (min-width: $breakpoint-md) {
    font-size: 12px;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 14px;
  }
}

.cart-message {
  font-size: 12px;
  font-weight: 900;
  margin-top: 5px;

  @include themify($themes) {
    color: themed('tableTh');
  }
}

.cart-body {
  width: 100%;
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  padding-bottom: 12px;

  @media (min-width: $breakpoint-md) {
    padding: 0;
    width: unset;
    display: table-row-group;
  }
}

.cart-tr-image {
  border-bottom: 1px solid;
  display: block;
  padding: 12px 0;

  @include themify($themes) {
    border-color: themed('cartBorder');
  }
}
