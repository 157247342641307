.checkout {
  padding: 5px 7px;
  display: flex;
  flex-direction: column;
  min-height: calc(100dvh - 52px);
  overflow-y: auto;

  @include themify($themes) {
    background-color: themed('gallery');
  }

  @media (min-width: $breakpoint-md) {
    padding: 31px 33px;
    flex-grow: unset;
    min-height: calc(100dvh - 67px);
  }

  @media (min-width: $breakpoint-lg) {
    margin-right: 10px;
    padding: 29px 34px 19px 34px;
    flex-grow: 1;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 36px 8.6%;
  }
}

.check-title {
  display: block;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;

  @include themify($themes) {
    color: themed('text');
  }
}

.check-mob-title {
  display: block;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  margin-top: 2px;
  margin-bottom: 14px;

  @include themify($themes) {
    color: themed('text');
    background-color: themed('back');
  }
}

.check-subtitle {
  font-size: 10px;
  font-weight: 500;
  display: block;
  margin-bottom: 20px;

  @media (min-width: $breakpoint-md) {
    font-size: 12px;
    font-weight: 900;
    margin: 20px 0;
  }

  @media (min-width: $breakpoint-xl) {
    margin: 22px 0;
  }

  @include themify($themes) {
    color: themed('tableTh');
  }
}

.check-radio-label {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  width: fit-content;
  cursor: pointer;

  @media (min-width: $breakpoint-md) {
    display: inline-flex;
  }

  &:first-child {
    margin-bottom: 14px;

    @media (min-width: $breakpoint-md) {
      margin-right: 40px;
      margin-bottom: 0;
    }

    @media (min-width: $breakpoint-xl) {
      margin-bottom: 14px;
      margin-right: 0;
    }
  }

  @include themify($themes) {
    color: themed('text');
  }

  @media (min-width: $breakpoint-xl) {
    display: flex;
  }
}

.check-radio-input {
  display: block;
  margin-right: 10px;

  &:checked ~ .check-radio-icon {
    border: 4px solid;

    @include themify($themes) {
      border-color: themed('btnMain');
    }
  }
}

.check-radio-icon {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid;
  margin-right: 10px;
  cursor: pointer;

  @include themify($themes) {
    border-color: themed('radioBorder');
    background-color: themed('gallery');
  }
}

.check-shipping-inputs {
  margin-top: 18px;

  @media (min-width: $breakpoint-md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 66px;
    row-gap: 12px;
    margin-top: 20px;
  }

  @media (min-width: $breakpoint-lg) {
    margin-left: -16px;
    margin-right: 35%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: unset;
    row-gap: 14px;
  }

  @media (min-width: $breakpoint-xl) {
    margin-top: 20px;
    margin-left: -10px;
    margin-right: 38%;
    row-gap: 16px;
  }
}

.check-col {
  position: relative;
}

.check-col,
.check-attach .check-col {
  margin-bottom: 9px;

  @media (min-width: $breakpoint-md) {
    margin-bottom: 0;
  }

  @media (min-width: $breakpoint-lg) {
    padding: 0 16px;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 0 10px;
  }

  .dropdown-text {
    font-size: 11px;
    font-weight: 500;

    @media (min-width: $breakpoint-md) {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .dropdown-label {
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 4px;
    margin-left: 6px;

    @media (min-width: $breakpoint-md) {
      font-size: 12px;
      font-weight: 900;
      margin-left: 0;
      margin-bottom: 6px;
    }
  }
}

.check-input {
  height: 37px;
  width: 100%;
  border: 1px solid;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  padding: 10px 14px;

  .check-error & {
    @include themify($themes) {
      border-color: #da7d82;
    }
  }

  @include themify($themes) {
    border-color: themed('inputBorder');
    background-color: themed('gallery');
  }

  @media (min-width: $breakpoint-md) {
    font-size: 14px;
    font-weight: normal;
  }

  @include themify($themes) {
    color: themed('text');
  }
}

.check-body {
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border: none;
    background-color: #fff;
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e9e9ed;
    border-radius: 3px;
    width: 6px;
    height: 6px;
  }

  @media (min-width: $breakpoint-md) {
    max-height: calc(100dvh - 221px);
    overflow-y: auto;
    padding-bottom: 20px;
  }

  @media (min-width: $breakpoint-xl) {
    max-height: calc(100dvh - 273px);
    overflow-y: auto;
  }
}

.check-footer {
  width: 100%;
  margin-top: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 30px 7px 5px 7px;
  margin-bottom: 5px;
  z-index: 1;
  background-color: #ffffff;

  @include themify($themes) {
    border-color: themed('cartBorder');
    background-color: themed('gallery');

    @media (min-width: $breakpoint-lg) {
      background-color: unset;
    }
  }

  @media (min-width: $breakpoint-md) {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    border-top: 1px solid $border-color;
    padding-top: 22px;
    background-color: #ffffff;
  }

  @media (min-width: $breakpoint-lg) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0 0 0;
    margin-bottom: 0;
    border-top: 1px solid;
    background-color: #ffffff;
  }

  @media (min-width: $breakpoint-xl) {
    padding-top: 22px;
    background-color: #ffffff;
  }
}

[data-open="true"] {
  margin-top: 200px;
}

.check-sidebar-item {
  padding-bottom: 4px;

  .confirm-list & {
    padding-bottom: 0;
  }
}

.check-info {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.check-attach {
  display: flex;
  align-items: flex-start;
}

.check-attach-img-container {
  width: 100%;
  height: 80px;
}

.check-attach-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.check-attach-card {
  width: 110px;
  position: relative;
}

.check-attach-card-title {
  display: block;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  margin-top: 5px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  @include themify($themes) {
    color: themed('text');
  }
}

.check-error-message {
  display: block;
  font-size: 10px;
  font-weight: 900;
  color: #da7d82;
  position: absolute;
  top: 100%;
  margin-top: 2px;
}

.check-col-middle {
  @media (min-width: $breakpoint-md) {
    grid-column: 1 / 3;
  }

  @media (min-width: $breakpoint-lg) {
    grid-column: unset;
  }
}

.check-input-container {
  position: relative;
}

.check-pass.icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translate(0, -50%);
  display: block;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.check-link {
  font-size: 12px;
  margin-left: auto;
  text-decoration: underline;
  cursor: pointer;

  @include themify($themes) {
    color: themed('tableTh');
  }
}

.check-titles-container {
  display: flex;
  align-items: center;
  margin-right: calc(35% + 16px);
}
