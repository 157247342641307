// .container {
//   @include container(0px);
// }

.root {
  min-height: 100dvh;
}

.col {
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.main-container {
  display: flex;
  padding: 0 5px;
  flex-direction: column;
  height: auto;
  flex: 1 1 auto;

  @media (min-width: $breakpoint-md) {
    height: calc(100% - 118px);
    padding: 0 10px;
  }

  @media (min-width: $breakpoint-lg) {
    flex-direction: row;
    padding: 0 10px;
    height: auto;
  }
}

.main-container-col.col {
  height: 100%;
  flex-basis: unset;
  flex-grow: unset;

  @media (min-width: $breakpoint-lg) {
    height: auto;
    flex-basis: 0;
    flex-grow: 1;
    padding-right: 10px;
  }
}

@include col(0, 14);

.check-attach {
  @include col(0, 12);
}
