.d-flex {
  display: flex;
}

.a-center {
  align-items: center;
}

.j-center {
  justify-content: center;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.hidden {
  opacity: 0;
  position: absolute;
  z-index: -10;
  width: 0;
  height: 0;
}

.shadow {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.3);
}

.text-center {
  text-align: center;
}