.stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 22px;

  @media (min-width: $breakpoint-xl) {
    margin-right: 10%;
  }
}

.stepper-step {
  display: flex;
  flex-direction: column;
  flex: 1;

  &:not(:first-child) {
    &::before {
      content: '';
      position: relative;
      top: 8px;
      width: calc(100% - 16px);
      left: calc(-100% + 16px);
      height: 2px;
      order: -1;

      @include themify($themes) {
        background-color: themed('stepper');
      }
    }
  }

  &.is-active {
    &::before {
      @include themify($themes) {
        background-color: themed('btnMain');
      }
    }
  }
}

.stepper-input {
  width: 16px;
  height: 16px;
  position: relative;
  opacity: 0;
  z-index: 1;
  cursor: pointer;

  @include themify($themes) {
    color: themed('btnMain');
  }

  &:checked {
    @at-root p#{&} {
      background-color: red;
    }
  }

  &:checked ~ .stepper-label {
    &::after {
      @include themify($themes) {
        border-color: themed('btnMain');
      }
    }

    &::before {
      @include themify($themes) {
        background-color: themed('btnMain');
      }
    }
  }
}

%step {
  content: '';
  display: block;
  position: absolute;
  border-radius: 50%;
  top: -15px;
  transform: translate(0, -50%);

  @media (min-width: $breakpoint-xl) {
    top: -28px;
  }
}

.stepper-label {
  display: block;
  font-size: 10px;
  font-weight: 900;
  margin-top: 7px;
  text-transform: uppercase;
  position: relative;

  @media (min-width: $breakpoint-xl) {
    font-size: 12px;
    margin-top: 20px;
  }

  @include themify($themes) {
    color: themed('tableTh');
  }

  .stepper-step.is-active & {
    @include themify($themes) {
      color: themed('text');
    }
  }

  &::after {
    @extend %step;
    width: 16px;
    height: 16px;
    border: 2px solid;
    left: 0;

    @include themify($themes) {
      border-color: themed('stepperLabel');
    }
  }

  &::before {
    @extend %step;
    width: 8px;
    height: 8px;
    left: 4px;

    @include themify($themes) {
      background-color: themed('stepperLabel');
    }
  }
}
