.menu {
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 12px 15px 15px;
  margin-bottom: 5px;
  margin-top: 0;

  @include themify($themes) {
    background-color: themed('gallery');
  }

  @media (min-width: $breakpoint-md) {
    height: 52px;
    padding: 14px 6px;
    margin-bottom: 10px;
    margin-top: 0;
  }
}

.menu-btn {
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;

  &:focus {
    outline: none;
  }

  @media (min-width: $breakpoint-md) {
    width: 24px;
    height: 24px;
    margin-left: 8px;
  }
}

.menu-title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  display: block;
  text-align: center;
  flex: 1 1 0;

  @include themify($themes) {
    color: themed('text');
  }
}

.menu-list {
  position: fixed;
  top: 0;
  left: 0;
  width: 85%;
  background-color: $default-color;
  padding: 5px 14px;
  height: 100dvh;
  z-index: 20;

  @include themify($themes) {
    background-color: themed('gallery');
  }

  &.is-opened {
    -webkit-animation: slide-in-left 0.5s
      cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
  }
}

.menu-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 8px;
}

.menu-list-header-logo {
  width: 28px;
  height: 14px;
}

.menu-list-header-btn {
  padding: 7px;
  background-color: $secondary-color;
  border: none;

  &:focus {
    outline: none;
  }
}

.menu-list-header-icon {
  width: 12px;
  height: 12px;
}

.menu-list-body {
  margin: 17px 0;
  height: calc(100dvh - 126px); //header + footer + paddings
  overflow-y: auto;
}

.menu-list-item {
  font-size: 13px;
  font-weight: 500;
  color: #9b9ca9;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  margin-bottom: 16px;
  padding-right: 15px;
  position: relative;

  &.is-active {
    @include themify($themes) {
      color: themed('text');
    }
  }
}

.menu-list-icon {
  width: 14px;
  height: 14px;
  margin-right: 11px;
}

.submenu {
  border-left: 1px solid #d9d9d9;
  padding-left: 17px;
  margin-bottom: 8px;
  margin-left: 6px;
}

.submenu-item {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  padding: 7px 15px;
  color: #9b9ca9;
  margin-bottom: 2px;
  min-height: 35px;
  text-transform: capitalize;

  &.is-active {
    background-color: $secondary-color;
    color: $theme-color;
  }
}

.menu-footer {
  margin-bottom: 15px;
}

.menu-footer-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.menu-dropdown {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 10px;
  height: 10px;

  .menu-list-item.is-active > & {
    transform: translate(0, -50%) rotate(180deg);
  }
}

.menu-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 0;
}

.menu-controls-btn {
  height: 32px;
  min-height: unset;
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  padding: 8px 12px;
}

.menu-controls-icon {
  width: 12px;
  height: 12px;
  margin-right: 7px;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.menu-header-logo {
  height: 14px;
  width: 26px;
}
