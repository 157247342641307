*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*,
*:active,
*:focus {
  font-family: 'Avenir', sans-serif;
  outline: none;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #e9e9ed #fff;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border: none;
    background-color: #fff;
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e9e9ed;
    border-radius: 2px;
    width: 4px;
    height: 4px;
  }
}

html,
body {
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100dvh;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.body {
  height: 100dvh;
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-lg) {
    display: block;
  }

  @include themify($themes) {
    background-color: themed('back');
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none; // Yeah, yeah everybody write about it
}

input[type='number'],
input[type='number']:hover,
input[type='number']:focus {
  appearance: none;
  -moz-appearance: textfield;
}
