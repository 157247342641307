.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: $breakpoint-lg) {
    height: calc(100% - 50px);
    margin-bottom: 10px;
  }

  @media (min-width: $breakpoint-xl) {
    height: calc(100% - 60px);
  }
}

.content-container {
  border: 2px dashed #c7c7c7;
  height: 100%;
  background-color: $default-color;
  flex: 1 1 0;
  position: relative;

  @include themify($themes) {
    border-color: themed('contentBorder');
    background-color: themed('contentBack');
  }

  &.is-active {
    background-color: #7d7e8d;
    border: none;

    @media (min-width: $breakpoint-md) {
      flex: 1 1 0;
      height: 100%;
    }
  }

  &.is-drag-active {
    background-color: #c2c2cc;
    border: 2px dashed #fdfa51;
  }

  &.is-loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: $breakpoint-lg) {
    padding: 0;
  }
}

.content-title {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 6px;
  text-align: center;
  color: $main-color;

  @media (min-width: $breakpoint-md) {
    font-size: 16px;
    text-transform: unset;
    margin-bottom: 10px;
  }

  @media (min-width: $breakpoint-lg) {
    font-size: 16px;
    line-height: 1.19;
    margin-bottom: 14px;
    text-transform: unset;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 22px;
    line-height: 0.86;
  }
}

.content-subtitle {
  font-size: 11px;
  font-weight: 500;
  line-height: 1.27;
  margin-bottom: 18px;
  display: block;
  text-align: center;

  @include themify($themes) {
    color: themed('contentText');
  }

  @media (min-width: $breakpoint-md) {
    font-size: 13px;
    margin-bottom: 26px;
  }

  @media (min-width: $breakpoint-lg) {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 26px;
    line-height: 1.23;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 14px;
    font-weight: 900;
    line-height: 1.36;
    width: 28%;
    margin-bottom: 24px;
  }
}

.content-drag {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-btn {
  display: block;
  width: 43px;
  height: 43px;
  padding: 11px;
  border: none;
  cursor: pointer;
  border: 1px dashed;
  background-color: transparent;
  margin: 0 14px;

  @include themify($themes) {
    border-color: themed('contentBtn');
  }

  &:focus {
    outline: none;
  }

  @media (min-width: $breakpoint-md) {
    width: 53px;
    height: 53px;
    margin: 0 20px;
  }

  @media (min-width: $breakpoint-lg) {
    width: 54px;
    height: 54px;
    margin: 0 20px;
  }

  @media (min-width: $breakpoint-xl) {
    width: 62px;
    height: 62px;
    padding: 15px;
  }
}

.content-btn-file {
  font-size: 10px;
  font-weight: 900;
  color: $theme-color;
  text-transform: uppercase;
  padding: 10px;
  height: 35px;
  min-height: unset;
  width: 202px;
  margin-bottom: 18px;

  @media (min-width: $breakpoint-md) {
    text-transform: capitalize;
    font-size: 14px;
    margin-bottom: 26px;
  }
}

// add temporary classes it probably need to move them somewhere

.content-icon {
  position: absolute;
  top: 0;
  z-index: 1000;
}

.content-control-btn {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 2px;
  z-index: 2;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.zoomout {
    bottom: 10px;
    right: 10px;
  }

  &.zoomin {
    bottom: 10px;
    right: 44px;
  }

  &.default {
    right: 10px;
    top: 50%;
    transform: translate(0, -114%);
  }

  &.room {
    right: 10px;
    top: 50%;
    transform: translate(0, 14%);
  }

  &.cancel {
    top: 10px;
    right: 44px;
  }

  &.apply {
    top: 10px;
    right: 10px;
  }

  &.dpi {
    top: 10px;
    left: 10px;
  }

  &.dpi {
    font-size: 10px;
    font-weight: 500;
    width: fit-content;
    padding: 10px;
  }

  &.border {
    border: 2px solid red;
  }
}

.content-control-icon {
  width: 18px;
  height: 18px;

  .apply &,
  .cancel & {
    width: 15px;
    height: 15px;
  }
}

.content-angle {
  position: absolute;
}

.content-upload {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
